import { put, call, takeEvery, select } from 'redux-saga/effects';
import { ducks, HTTPMethod } from 'pqbc-vas-core';
import { TicketType } from 'pqbc-vas-native-bridge';
import history from '../../../../app/history';
import * as actions from '../actions';
import * as selectors from '../selectors';
import * as userSelectors from '../../user/selectors';
import bridge from '../../../../app/bridge';
import TrackingEvents from '../../../../utils/tracking';

import {
  TicketsApiResponseBody,
  Ticket,
  AvailableProductsApiResponseBody,
  Product,
} from '../types';
import { localStorageContext } from '../constants';

export function* fetchAvailableTicketsSaga() {
  try {
    const responseRunning: TicketsApiResponseBody = yield call(
      ducks.Api.sagas.callApi,
      '/sessions?status=running&type=nmbs',
      {
        method: HTTPMethod.GET,
        headers: { '4411-client-secret': process.env.REACT_APP_CLIENT_SECRET },
      },
    );
    const responseFuture: TicketsApiResponseBody = yield call(
      ducks.Api.sagas.callApi,
      '/sessions?status=future&type=nmbs',
      {
        method: HTTPMethod.GET,
        headers: { '4411-client-secret': process.env.REACT_APP_CLIENT_SECRET },
      },
    );
    const responseExpired: TicketsApiResponseBody = yield call(
      ducks.Api.sagas.callApi,
      '/sessions?status=stopped&type=nmbs',
      {
        method: HTTPMethod.GET,
        headers: { '4411-client-secret': process.env.REACT_APP_CLIENT_SECRET },
      },
    );

    const tickets = {
      available: responseRunning.content.concat(responseFuture.content),
      expired: responseExpired.content,
    };

    const ticketsToSync: Ticket[] = responseRunning.content
      .concat(responseFuture.content)
      .concat(responseExpired.content);

    yield put(actions.getMyTickets.success(tickets));
    bridge.syncTickets({ type: TicketType.Nmbs, tickets: ticketsToSync });
  } catch (error) {
    yield put(actions.getMyTickets.failure(error));
  }
}

export function* fetchAvailableProductSaga(
  action: ReturnType<typeof actions.getAvailableProducts.request>,
) {
  try {
    const currentTraveler = yield select(userSelectors.getCurrentTraveler);

    const today = new Date();

    let travelerType = 'child';
    if (
      new Date(currentTraveler.birthdate).getTime() <=
      new Date(
        today.getFullYear() - 18,
        today.getMonth(),
        today.getDate(),
      ).getTime()
    ) {
      travelerType = 'adult';
    }

    const params = new URLSearchParams();
    const {
      departureDate,
      departureStationId,
      destinationStationId,
      journeyType,
      travelClass,
    } = action.payload;
    params.append('departure_date', `${departureDate}`);
    params.append('departure_station_id', departureStationId);
    params.append('destination_station_id', `${destinationStationId}`);
    params.append('journey_type', `${journeyType}`);
    params.append('travel_class', `${travelClass}`);
    params.append('traveler_type', `${travelerType}`);
    const url = `/nmbs/products?${params.toString()}`;

    const response: AvailableProductsApiResponseBody = yield call(
      ducks.Api.sagas.callApi,
      url,
      {
        method: HTTPMethod.GET,
        headers: { '4411-client-secret': process.env.REACT_APP_CLIENT_SECRET },
      },
    );
    // Children with a large families card travel for free
    const filtered = response.content.filter(
      (product: Product) => product.id !== '40000105',
    );
    yield put(actions.getAvailableProducts.success(filtered));
  } catch (error) {
    yield put(actions.getAvailableProducts.failure(error));
  }
}

function* buyTicketSaga() {
  try {
    const context = JSON.parse(
      window.localStorage.getItem(localStorageContext) || '',
    );

    yield call(ducks.Api.sagas.callApi, '/nmbs/tickets', {
      method: HTTPMethod.POST,
      headers: {
        '4411-client-secret': process.env.REACT_APP_CLIENT_SECRET,
      },
      body: {
        traveler_id: context.travelerId,
        departure_date: context.departureDate,
        departure_station_id: context.departureStationId,
        destination_station_id: context.destinationStationId,
        travel_class: context.travelClass,
        traveler_type: context.travelerType,
        product_id: context.productId,
        price: context.price,
        journey_type: context.journeyType,
      },
    });

    window.localStorage.removeItem(localStorageContext);
    bridge.trackEvent(TrackingEvents.SuccessBuyTicket);
    yield call(fetchAvailableTicketsSaga);
    yield put(actions.buyTicket.success());
    history.push('/tickets');
  } catch (error) {
    bridge.trackEvent(TrackingEvents.FailedBuyTicket);
    yield put(actions.buyTicket.failure(error));
  }
}

export function* startPayconiqPaymentSaga(
  action: ReturnType<typeof actions.startPayconiqPayment.request>,
) {
  try {
    const ticketData = yield select(selectors.getNewTicketData);
    const currentTraveler = yield select(userSelectors.getCurrentTraveler);
    const today = new Date();
    const minAdultDate = new Date(
      today.getFullYear() - 18,
      today.getMonth(),
      today.getDate(),
    ).getTime();

    const context = {
      travelerId: currentTraveler.traveler_id,
      departureDate: ticketData.departureDate,
      departureStationId: ticketData.departureStation.id,
      destinationStationId: ticketData.destinationStation.id,
      travelClass: ticketData.trainClass,
      travelerType:
        currentTraveler.birthdate > minAdultDate ? 'child' : 'adult',
      productId: ticketData.productId,
      price: ticketData.price,
      journeyType: ticketData.journeyType,
    };

    window.localStorage.setItem(localStorageContext, JSON.stringify(context));

    yield bridge.startPayconiqPaymentFlow({
      productProfileId: process.env.REACT_APP_PAYCONIQ_PROFILE_ID,
      amount: action.payload.cost,
      reference: `customer:${action.payload.customerNumber}`,
    });
  } catch (error) {
    bridge.trackEvent(TrackingEvents.FailedBuyTicket);
    yield put(actions.startPayconiqPayment.failure(error));
  }
}

export function* TicketsSaga() {
  yield takeEvery(actions.getMyTickets.request, fetchAvailableTicketsSaga);
  yield takeEvery(
    actions.getAvailableProducts.request,
    fetchAvailableProductSaga,
  );
  yield takeEvery(
    actions.startPayconiqPayment.request,
    startPayconiqPaymentSaga,
  );
  yield takeEvery(actions.buyTicket.request, buyTicketSaga);
}
