import { put, call, takeEvery, select } from 'redux-saga/effects';
import { ducks, HTTPMethod, toTitleCase } from 'pqbc-vas-core';
import { REHYDRATE } from 'redux-persist';
import * as actions from '../actions';
import { StationsApiResponseBody } from '../types';

export function* fetchStationsSaga() {
  const isLoggedIn = yield select(ducks.Auth.selectors.isLoggedIn);

  if (!isLoggedIn) {
    return;
  }

  try {
    const response: StationsApiResponseBody = yield call(
      ducks.Api.sagas.callApi,
      '/nmbs/stations',
      {
        method: HTTPMethod.GET,
        headers: { '4411-client-secret': process.env.REACT_APP_CLIENT_SECRET },
      },
    );
    const filtered = response.content
      .filter(station => Boolean(station.name))
      // Cannot filter on '(' because of Belgian station 'La Roche (Brabant)'
      .filter(station => !station.name.match(/\((NL|D|L|F)\)/))
      .map(station => ({ id: station.id, name: toTitleCase(station.name) }));
    yield put(actions.getStations.success(filtered));
  } catch (error) {
    yield put(actions.getStations.failure(error));
  }
}

export function* StationsSaga() {
  yield takeEvery(
    [REHYDRATE, ducks.Auth.actions.getAccessToken.success],
    fetchStationsSaga,
  );
}
