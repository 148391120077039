import { connect } from 'react-redux';
import TicketOverviewView from './TicketOverviewView';
import { State } from '../../core/redux/reducers';
import { User, Tickets, Status } from '../../core/ducks';

const mapStateToProps = (state: State) => ({
  myAvailableTickets: Tickets.selectors.getMyAvailableTickets(state),
  myExpiredTickets: Tickets.selectors.getMyExpiredTickets(state),
  isLoading: Status.selectors.isLoading('GET_MY_TICKETS')(state),
  isBuyingTicket: Status.selectors.isLoading('BUY_TICKET')(state),
});

const mapDispatchToProps = {
  getMyTickets: Tickets.actions.getMyTickets.request,
  getCustomerProfile: User.actions.getCustomerProfile.request,
  buyTicketRequest: Tickets.actions.buyTicket.request,
};

export type TicketOverviewProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TicketOverviewView);
