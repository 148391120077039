import {
  CardContent,
  NavigationBarButton,
  Settings24Icon,
  useAppNavigation,
  usePopover,
  Spinner,
  NavigationBarTabs,
  Flex,
} from 'pqbc-vas-design-system';
import i18n from 'i18next';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import bridge from '../../app/bridge';
import {
  SettingsBottomSheetDialog,
  InfiniteScrollPullRefresh,
} from '../../components';
import TrackingEvents from '../../utils/tracking';
import * as S from './styles';
import { TicketOverviewProps } from './TicketOverviewContainer';
import ActiveTicket from './ActiveTicket';
import ExpiredTicket from './ExpiredTicket';
import TicketImage from '../../assets/images/ticket.svg';
import { TicketType } from './types';
import { Ticket } from '../../core/ducks/tickets/types';

export const sortTicketsDescendingOnDepartureDate = (tickets: Ticket[]) => {
  return tickets.sort((a, b) => {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return (
      new Date(b.details.departure_date).getTime() -
      new Date(a.details.departure_date).getTime()
    );
  });
};

export const sortTicketsAscendingOnDepartureDate = (tickets: Ticket[]) => {
  return tickets.sort((a, b) => {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return (
      new Date(a.details.departure_date).getTime() -
      new Date(b.details.departure_date).getTime()
    );
  });
};

const TicketOverviewView: React.FC<TicketOverviewProps> = ({
  getMyTickets,
  getCustomerProfile,
  myAvailableTickets,
  myExpiredTickets,
  isLoading,
  isBuyingTicket,
  buyTicketRequest,
}) => {
  const { open, handleOpen, handleClose } = usePopover();
  const { t } = useTranslation();
  const history = useHistory();
  const [selectedTicketType, setSelectedTicketType] = useState(
    TicketType.Active,
  );

  const handleOpenHelp = () => {
    handleOpen();
    bridge.trackEvent(TrackingEvents.OpenSettings);
  };

  useEffect(() => {
    const startListeningForPaymentCallback = async () => {
      const paymentSuccess = await bridge.onPaymentFinished();
      // Each time an event is received we restart this listener to make sure future events are captured too.
      startListeningForPaymentCallback();

      if (paymentSuccess) {
        buyTicketRequest();
      }
    };
    startListeningForPaymentCallback();
  }, [buyTicketRequest]);

  useEffect(() => {
    getMyTickets();
    getCustomerProfile();
  }, [getMyTickets, getCustomerProfile]);

  useEffect(() => {
    if (selectedTicketType === TicketType.Expired) {
      bridge.trackEvent(TrackingEvents.ShowTicketHistory);
    }
  }, [selectedTicketType]);

  useAppNavigation(
    {
      right: (
        <NavigationBarButton onClick={handleOpenHelp}>
          <Settings24Icon />
        </NavigationBarButton>
      ),
      extension: (
        <S.TabsContainer>
          <NavigationBarTabs.Group
            name='typeOfTickets'
            value={selectedTicketType}
            onChange={(event: any) => {
              setSelectedTicketType(event.target.value);
            }}
          >
            <NavigationBarTabs.TabItem value={TicketType.Active}>
              {t('overview.active')}
            </NavigationBarTabs.TabItem>
            <NavigationBarTabs.TabItem value={TicketType.Expired}>
              {t('overview.expired')}
            </NavigationBarTabs.TabItem>
          </NavigationBarTabs.Group>
        </S.TabsContainer>
      ),
    },
    [selectedTicketType, i18n.language],
  );

  const handleGetTicket = () => {
    bridge.trackEvent(TrackingEvents.ClickBuyTicket, {
      started_from: 'overview',
    });
    history.push('/tickets/buy');
  };

  const handlePullToRefresh = () => {
    getMyTickets();
  };

  return isLoading || isBuyingTicket ? (
    <S.NoTicketContainer>
      <CardContent>
        <Spinner />
        <S.EmptyViewText>{t('overview.loading')}</S.EmptyViewText>
      </CardContent>
    </S.NoTicketContainer>
  ) : (
    <>
      {selectedTicketType === TicketType.Active ? (
        <Flex flexDirection='column' flex='auto' justifyContent='space-between'>
          {myAvailableTickets.length > 0 ? (
            <InfiniteScrollPullRefresh
              dataLength={myAvailableTickets.length}
              next={() => {}}
              hasMore={false}
              refreshFunction={handlePullToRefresh}
            >
              <S.TicketsContainer>
                {sortTicketsAscendingOnDepartureDate(myAvailableTickets).map(
                  (ticket: Ticket) => (
                    <ActiveTicket key={ticket.id} ticket={ticket} />
                  ),
                )}
              </S.TicketsContainer>
            </InfiniteScrollPullRefresh>
          ) : (
            <S.NoTicketContainer>
              <CardContent>
                <S.NoTicketsImage src={TicketImage} objectFit='contain' />
                <S.EmptyViewText>{t('overview.noTickets')}</S.EmptyViewText>
              </CardContent>
            </S.NoTicketContainer>
          )}

          <S.GetTicketButton color='success' onClick={handleGetTicket}>
            {t('overview.getTicket')}
          </S.GetTicketButton>
        </Flex>
      ) : (
        <>
          {myExpiredTickets.length > 0 ? (
            <S.TicketsContainer>
              {sortTicketsDescendingOnDepartureDate(myExpiredTickets).map(
                (ticket: Ticket) => (
                  <ExpiredTicket key={ticket.id} ticket={ticket} />
                ),
              )}
            </S.TicketsContainer>
          ) : (
            <S.NoTicketContainer>
              <CardContent>
                <S.NoTicketsImage src={TicketImage} objectFit='contain' />
                <S.EmptyViewText>
                  {t('overview.noExpiredTickets')}
                </S.EmptyViewText>
              </CardContent>
            </S.NoTicketContainer>
          )}
        </>
      )}
      <SettingsBottomSheetDialog open={open} onClose={handleClose} />
    </>
  );
};

export default TicketOverviewView;
