import { Card, Flex } from 'pqbc-vas-design-system';
import React, { Reducer, useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import reverseIcon from '../../assets/images/reverse-icon.svg';
import routeIcon from '../../assets/images/route-icon.svg';
import { Station } from '../../core/ducks/stations/types';
import ClickableSearchItem from './SelectableSearchItem';
import { useStationSearch } from './stationSearch';
import * as S from './styles';

enum FocusableField {
  Origin,
  Destination,
}

interface RouteSelectorState {
  search: {
    focus?: FocusableField;
    origin: string;
    destination: string;
  };
}

const initialState: RouteSelectorState = {
  search: { origin: '', destination: '' },
};

enum Action {
  search,
  clearField,
  clearFocus,
  reverse,
  selectStation,
  initializeValues,
}

const reducer: Reducer<
  RouteSelectorState,
  {
    type: Action;
    payload?: {
      field?: FocusableField;
      station?: Station;
      value?: string;
      origin?: string;
      destination?: string;
    };
  }
> = (state, action) => {
  switch (action.type) {
    case Action.search:
      return {
        ...state,
        search: {
          ...state.search,
          focus: action.payload?.field,
          origin:
            action.payload?.field === FocusableField.Origin
              ? (action.payload?.value as string)
              : state.search.origin,
          destination:
            action.payload?.field === FocusableField.Destination
              ? (action.payload?.value as string)
              : state.search.destination,
        },
      };
    case Action.clearFocus:
      return {
        ...state,
        search: {
          ...state.search,
          focus: initialState.search.focus,
        },
      };
    case Action.clearField: {
      return {
        ...state,
        search: {
          ...state.search,
          focus: initialState.search.focus,
          origin:
            state.search.focus === FocusableField.Origin
              ? initialState.search.origin
              : state.search.origin,
          destination:
            state.search.focus === FocusableField.Destination
              ? initialState.search.destination
              : state.search.destination,
        },
      };
    }
    case Action.reverse:
      return {
        ...state,
        search: {
          origin: state.search.destination,
          destination: state.search.origin,
          focus: undefined,
        },
      };
    case Action.selectStation:
      return {
        ...state,
        search: {
          ...state.search,
          origin:
            state.search.focus === FocusableField.Origin
              ? (action.payload?.station as Station).name
              : state.search.origin,
          destination:
            state.search.focus === FocusableField.Destination
              ? (action.payload?.station as Station).name
              : state.search.destination,
          focus: undefined,
        },
      };
    case Action.initializeValues:
      if (!action.payload?.origin || !action.payload?.destination) {
        return state;
      }
      return {
        ...state,
        search: {
          origin: action.payload?.origin,
          destination: action.payload?.destination,
        },
      };
    default:
      return state;
  }
};

const getFocusedFieldValue = (searchState: RouteSelectorState['search']) => {
  switch (searchState.focus) {
    case FocusableField.Origin:
      return searchState.origin;
    case FocusableField.Destination:
      return searchState.destination;
    default:
      return '';
  }
};

interface Props {
  stations: Station[];
  selectedStations: {
    departure: {
      id?: string;
      name?: string;
    };
    destination: {
      id?: string;
      name?: string;
    };
  };
}

const RouteSelector: React.FC<Props> = ({ stations, selectedStations }) => {
  const { t } = useTranslation();
  const dispatchToReduxState = useDispatch();
  const [state, dispatch] = useReducer(reducer, initialState);
  const focusedFieldValue = getFocusedFieldValue(state.search);
  // Spaces are replaced so `GENT-SINT-PIETERS` will match when searching `gent sint`
  const needle = focusedFieldValue.replace(' ', '');
  const matchingStations = useStationSearch(needle, stations);

  useEffect(() => {
    if (selectedStations.departure && selectedStations.destination) {
      dispatch({
        type: Action.initializeValues,
        payload: {
          origin: selectedStations.departure.name,
          destination: selectedStations.destination.name,
        },
      });
    }
  }, [selectedStations]);

  const onBlur = () => {
    if (
      (state.search.focus === FocusableField.Origin &&
        selectedStations.departure &&
        state.search.origin === selectedStations.departure.name) ||
      (state.search.focus === FocusableField.Destination &&
        selectedStations.destination &&
        state.search.destination === selectedStations.destination.name)
    ) {
      dispatch({ type: Action.clearFocus });
    } else {
      dispatch({ type: Action.clearField });
      dispatchToReduxState({
        type: 'SAVE_NEW_TICKET',
        payload: {
          departureStation:
            state.search.focus === FocusableField.Origin
              ? { id: '', name: '' }
              : undefined,
          destinationStation:
            state.search.focus === FocusableField.Destination
              ? { id: '', name: '' }
              : undefined,
        },
      });
    }
  };

  const onSearch = (field: FocusableField, value: string) =>
    dispatch({ type: Action.search, payload: { field, value } });

  const onKeyUp = (field: FocusableField, key: string) => {
    if (matchingStations.length > 0 && key === 'Enter') {
      onSelectStation(field, matchingStations[0]);
      dispatch({ type: Action.clearFocus });
    }
  };

  const onSelectStation = (field: FocusableField, station: Station) => {
    dispatch({ type: Action.selectStation, payload: { field, station } });
    dispatchToReduxState({
      type: 'SAVE_NEW_TICKET',
      payload: {
        departureStation: field === FocusableField.Origin ? station : undefined,
        destinationStation:
          field === FocusableField.Destination ? station : undefined,
      },
    });
  };

  return (
    <S.Wrapper>
      <Card>
        <S.CardContent>
          <Flex justifyContent='space-between' alignItems='center'>
            <S.RouteIcon src={routeIcon} />
            <S.Fields>
              <S.Input
                onBlur={onBlur}
                onKeyUp={event => onKeyUp(FocusableField.Origin, event.key)}
                data-testid='input-origin'
                value={state.search.origin}
                onChange={event =>
                  onSearch(FocusableField.Origin, event.target.value)
                }
                placeholder={t('getTicket.originPlaceholder')}
              />
              <S.Divider />
              <S.Input
                onBlur={onBlur}
                onKeyUp={event =>
                  onKeyUp(FocusableField.Destination, event.key)
                }
                data-testid='input-destination'
                value={state.search.destination}
                onChange={event =>
                  onSearch(FocusableField.Destination, event.target.value)
                }
                placeholder={t('getTicket.destinationPlaceholder')}
                isBottom
              />
            </S.Fields>
            <S.ReverseButton
              onClick={() => {
                dispatchToReduxState({
                  type: 'SAVE_NEW_TICKET',
                  payload: {
                    departureStation: {
                      id: selectedStations.destination.id,
                      name: selectedStations.destination.name,
                    },
                    destinationStation: {
                      id: selectedStations.departure.id,
                      name: selectedStations.departure.name,
                    },
                  },
                });
                dispatch({ type: Action.reverse });
              }}
            >
              <S.ReverseIcon src={reverseIcon} />
            </S.ReverseButton>
          </Flex>
        </S.CardContent>
      </Card>
      {typeof state.search.focus !== 'undefined' &&
        matchingStations.length > 0 && (
          <S.StationsCard>
            <S.StationsCardContent>
              {matchingStations.map((station, index) => (
                <ClickableSearchItem
                  key={`${station.id}-${station.name}`}
                  highlight={focusedFieldValue}
                  title={station.name}
                  onMouseDown={() =>
                    onSelectStation(
                      state.search.focus as FocusableField,
                      station,
                    )
                  }
                  isFocused={index === 0}
                />
              ))}
            </S.StationsCardContent>
          </S.StationsCard>
        )}
    </S.Wrapper>
  );
};

export default RouteSelector;
