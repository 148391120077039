import styled from 'styled-components';
import {
  Card as DSCard,
  CardContent as DSCardContent,
  Divider as DSDivider,
  Checkbox as DSCheckbox,
  ExternalLink as DSLink,
  Flex,
  Button,
} from 'pqbc-vas-design-system';

export const TicketContent = styled(DSCardContent)`
  padding: 16px;
  color: ${p => p.theme.colors.secondary500};
`;

export const TicketBox = styled.div`
  padding: 16px;
  background: ${p => p.theme.colors.secondary100};
  border-radius: 4px;
`;

export const RouteIcon = styled.img`
  margin-right: 8px;
`;

export const Fields = styled.div`
  flex-grow: 1;
  /* All children except first */
  & > * + * {
    margin-top: 8px;
  }
`;

export const StationContainer = styled.div`
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  width: 100%;
`;

export const Container = styled.div`
  width: 100%;
  text-align: left;
`;

export const DetailGroup = styled.div`
  /* All children except first */
  & > * + * {
    margin-top: 8px;
  }
`;

export const PriceBox = styled.div`
  margin-top: 18px;
`;

export const PriceGroup = styled.div`
  /* All children except first */
  & > * + * {
    margin-top: 20px;
  }
`;

export const PriceFlex = styled(Flex)`
  justify-content: space-between;
  align-items: flex-end;
`;

export const Price = styled.div`
  margin-right: 5px;
  font-size: 18px;
  font-weight: 700;
`;

export const Currency = styled.div`
  font-size: 15px;
`;

export const BoxDivider = styled(DSDivider)`
  background: ${p => p.theme.colors.secondary200};
`;

export const Icon = styled.img`
  height: 16px;
  width: 16px;
  margin-right: 8px;
`;

export const UserIcon = styled.img`
  height: 16px;
  width: 22.11px;
  margin-right: 1.89px;
`;

export const Total = styled.div`
  font-weight: 700;
  width: 100%;
`;

export const TCCard = styled(DSCard).attrs({})`
  font-size: 18px;
  margin-top: 8px;
  color: ${p => p.theme.colors.secondary500};
`;

export const TCFlex = styled(Flex)`
  justify-content: space-between;
  align-items: center;
`;

export const Checkbox = styled(DSCheckbox).attrs({})`
  margin-right: 12px;
`;

export const MaxWidth = styled.div`
  width: max-content;
`;

export const ExternalLink = styled(DSLink).attrs({})`
  font-size: 18px;
`;

export const SubmitButton = styled(Button).attrs({
  color: 'success',
})`
  margin-top: 8px;
  width: 100%;
`;
