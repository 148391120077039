import { FormikProps } from 'formik';

export const getError = <Values>(
  field: keyof Values,
  form: FormikProps<Values>,
) => {
  if (form.submitCount > 0 && form.errors[field]) {
    return form.errors[field];
  }
  return undefined;
};
