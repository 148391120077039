import {
  Card,
  Flex,
  CardContent,
  Divider,
  NavigationBarButton,
  Close24Icon,
  Back24Icon,
  useAppNavigation,
  Spinner,
} from 'pqbc-vas-design-system';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { formatDate, formatAmount, formatAppVersion } from 'pqbc-vas-core';
import bridge from '../../app/bridge';
import routeIcon from '../../assets/images/purchase-route-icon.svg';
import dateIcon from '../../assets/images/date.svg';
import ticketIcon from '../../assets/images/purchase-ticket.svg';
import userIcon from '../../assets/images/user.svg';
import { CancelGetTicketDialog } from '../../components';
import TrackingEvents from '../../utils/tracking';
import { TicketPurchaseOverviewProps } from './ticketPurchaseOverviewContainer';
import * as S from './styles';

const TicketPurchaseOverviewView: React.FC<TicketPurchaseOverviewProps> = ({
  currentTraveler,
  isLoading,
  newTicketData,
  startPayment,
  customerNumber,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState<boolean>(false);
  const [isTCChecked, setIsTCChecked] = useState<boolean>(false);

  const handleBack = () => {
    history.goBack();
  };

  const handleOpenTermsAndConditions = () => {
    bridge.trackEvent(TrackingEvents.OpenTermsAndConditions);
    const url = t('purchaseTicket.termsAndConditions.url');
    bridge.openUrl(url);
  };

  const handleTCChecked = () => {
    if (!isTCChecked) {
      bridge.trackEvent(TrackingEvents.AcceptTicketBuyTerms);
    }
    setIsTCChecked(!isTCChecked);
  };

  const handleBuyTicket = async () => {
    if (newTicketData.totalPrice) {
      bridge.trackEvent(TrackingEvents.PayTicket);
      startPayment({
        customerNumber,
        cost: newTicketData.totalPrice,
      });
      const appVersion = formatAppVersion(await bridge.getAppVersion());
      if (appVersion >= 5080) {
        bridge.markInUse();
      }
    }
  };

  useAppNavigation({
    left: (
      <NavigationBarButton onClick={handleBack}>
        <Back24Icon />
      </NavigationBarButton>
    ),
    title: t('purchaseTicket.title'),
    right: (
      <NavigationBarButton onClick={() => setIsCancelDialogOpen(true)}>
        <Close24Icon />
      </NavigationBarButton>
    ),
  });

  return (
    <>
      {isLoading ? (
        <Card>
          <S.TicketContent>
            <Spinner />
          </S.TicketContent>
        </Card>
      ) : (
        <>
          <Card>
            <S.TicketContent>
              <S.TicketBox>
                <Flex>
                  <S.RouteIcon src={routeIcon} />
                  <S.Fields>
                    <S.StationContainer>
                      {newTicketData.departureStation.name}
                    </S.StationContainer>
                    <S.StationContainer>
                      {newTicketData.destinationStation.name}
                    </S.StationContainer>
                  </S.Fields>
                </Flex>
                <S.BoxDivider />
                <S.DetailGroup>
                  <Flex>
                    <S.Icon src={dateIcon} />
                    {formatDate(new Date(newTicketData.departureDate))}
                  </Flex>
                  <Flex>
                    <S.Icon src={ticketIcon} />
                    {`${t(
                      `purchaseTicket.journeyType.${newTicketData.journeyType}`,
                    )} · ${t(
                      `purchaseTicket.trainClass.${newTicketData.trainClass}`,
                    )}`}
                  </Flex>
                  <Flex>
                    <S.UserIcon src={userIcon} />
                    {currentTraveler?.firstname} {currentTraveler?.lastname}
                  </Flex>
                </S.DetailGroup>
              </S.TicketBox>
              <S.PriceBox>
                <S.PriceGroup>
                  <S.PriceFlex>
                    <S.Container>
                      {t(`availableProducts.${newTicketData.productId}.name`)}
                    </S.Container>
                    {newTicketData.price ? (
                      <S.Price>
                        {formatAmount(newTicketData.price / 100)}
                      </S.Price>
                    ) : null}
                    <S.Currency>EUR</S.Currency>
                  </S.PriceFlex>
                  <S.PriceFlex>
                    <S.Container>{t('purchaseTicket.fee')}</S.Container>
                    {newTicketData.transactionCost ? (
                      <S.Price>
                        {formatAmount(newTicketData.transactionCost / 100)}
                      </S.Price>
                    ) : null}
                    <S.Currency>EUR</S.Currency>
                  </S.PriceFlex>
                </S.PriceGroup>
                <Divider />
                <S.PriceFlex>
                  <S.Total>{t('purchaseTicket.total')}</S.Total>
                  {newTicketData.totalPrice ? (
                    <S.Price>
                      {formatAmount(newTicketData.totalPrice / 100)}
                    </S.Price>
                  ) : null}
                  <S.Currency>EUR</S.Currency>
                </S.PriceFlex>
              </S.PriceBox>
            </S.TicketContent>
          </Card>
          <S.TCCard>
            <CardContent>
              <S.TCFlex>
                <S.MaxWidth>
                  <S.Checkbox
                    checked={isTCChecked}
                    onChange={handleTCChecked}
                  />
                </S.MaxWidth>
                <S.Fields>
                  <Trans
                    i18nKey='purchaseTicket.termsAndConditions.title'
                    components={[
                      <S.ExternalLink onClick={handleOpenTermsAndConditions} />,
                    ]}
                  />
                </S.Fields>
              </S.TCFlex>
            </CardContent>
          </S.TCCard>
          <S.SubmitButton
            type='submit'
            disabled={
              !isTCChecked ||
              !newTicketData.price ||
              !newTicketData.totalPrice ||
              !newTicketData.transactionCost
            }
            onClick={handleBuyTicket}
          >
            {t('purchaseTicket.confirm')}
          </S.SubmitButton>
          <CancelGetTicketDialog
            open={isCancelDialogOpen}
            onCancel={() => setIsCancelDialogOpen(false)}
            onConfirm={() => history.push('/tickets')}
          />
        </>
      )}
    </>
  );
};

export default TicketPurchaseOverviewView;
