import React from 'react';
import * as S from './styles';

const highlightString = (string: string, highlight: string) => {
  if (!highlight) {
    return string;
  }

  const lowerCaseString = string.toLocaleLowerCase();
  const lowerCaseHighlight = highlight.toLocaleLowerCase();

  return [...lowerCaseString].map((char, index) =>
    lowerCaseHighlight.includes(char) ? (
      // eslint-disable-next-line react/no-array-index-key
      <S.HighLightedText key={index}>{string.charAt(index)}</S.HighLightedText>
    ) : (
      string.charAt(index)
    ),
  );
};

interface Props {
  title: string;
  highlight: string;
  isFocused?: boolean;
  onMouseDown: () => void;
}

const ClickableSearchItem: React.FC<Props> = ({
  title,
  highlight,
  isFocused = false,
  onMouseDown,
}) => {
  const highLightedString = highlightString(title, highlight);
  return (
    <S.Clickable
      onMouseDown={onMouseDown}
      isFocused={isFocused}
      data-testid={`suggestedStation-${title}`}
    >
      {highLightedString}
    </S.Clickable>
  );
};

export default ClickableSearchItem;
