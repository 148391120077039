import React from 'react';
import { Dialog, Card, PopoverContent } from 'pqbc-vas-design-system';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import bridge from '../../app/bridge';
import TrackingEvents from '../../utils/tracking';

import * as S from './styles';

interface Props {
  open: boolean;
  onCancel: () => void;
}

const LargeFamilyReminderDialog: React.FC<Props> = ({ open, onCancel }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const handleConfirmDiscount = () => {
    bridge.trackEvent(TrackingEvents.ConfirmDiscount);
    history.push('/tickets/purchase');
  };

  return (
    <Dialog open={open} onDismiss={onCancel}>
      <PopoverContent>
        <Card>
          <Dialog.Content>
            <Dialog.Title>{t('largeFamily.warning.title')}</Dialog.Title>
            <S.DialogMessage>
              {t('largeFamily.warning.description')}
            </S.DialogMessage>
            <S.ButtonGroup alignItems='center' flexDirection='column'>
              <S.Button color='success' onClick={handleConfirmDiscount}>
                {t('largeFamily.warning.accept')}
              </S.Button>
              <S.Button color='secondary' outline onClick={onCancel}>
                {t('largeFamily.warning.decline')}
              </S.Button>
            </S.ButtonGroup>
          </Dialog.Content>
        </Card>
      </PopoverContent>
    </Dialog>
  );
};

export default LargeFamilyReminderDialog;
