enum TrackingEvents {
  Login = 'start_login',
  CompleteLogin = 'completed_login',
  ClickHelp = 'help',
  OpenPrivacyPolicy = 'privacy_policy',
  OpenTermsAndConditions = 't&c',
  ContactSupport = 'support',
  OpenFAQ = 'faq',
  OpenSettings = 'open_settings',
  LogOut = 'logout_start',
  CompleteLogout = 'logout_complete',
  ShowTicketDetail = 'ticket_detail',
  ShowTicketHistory = 'show_history',
  ShowTickets = 'show_tickets',
  ClickBuyTicket = 'start_buy_ticket',
  SelectTicket = 'choose_ticket',
  AcceptTicketBuyTerms = 'agree_t&c',
  PayTicket = 'complete_buy_ticket',
  FailedBuyTicket = 'failed_buy_ticket',
  SuccessBuyTicket = 'success_buy_ticket',
  ChooseTraveler = 'choose_traveler',
  SaveTraveler = 'save_traveler',
  ConfirmDiscount = 'confirm_discount',
}

export default TrackingEvents;
