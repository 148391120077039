import styled from 'styled-components';
import {
  Flex,
  ListItemLabel,
  Spinner as DSSpinner,
} from 'pqbc-vas-design-system';

export const LoadingContainer = styled(Flex)`
  flex-direction: column;
  height: 170px;
  align-items: center;
  justify-content: center;
`;

export const LoadingDescription = styled(Flex)`
  margin-top: 17px;
  color: ${p => p.theme.colors.neutral500};
  font-size: 16px;
`;

export const Spinner = styled(DSSpinner)`
  height: 22px;
  width: 48px;
`;

export const Product = styled(ListItemLabel)`
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
`;

export const ProductDescription = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  font-size: 18px;
  font-weight: 400;
`;

export const ProductTitle = styled(Flex)`
  text-transform: lowercase;
  display: inline-block;

  ::first-letter {
    text-transform: capitalize;
  }
`;

export const ProductSubtitle = styled(Flex)`
  font-weight: 400;
  margin-top: 3px;
  color: ${p => p.theme.colors.neutral600};
  font-size: 15px;
`;

export const Currency = styled.span`
  font-weight: 400;
  margin-left: 5px;
`;
