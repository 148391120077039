import React from 'react';
import { Dialog, Card, PopoverContent } from 'pqbc-vas-design-system';
import { useTranslation } from 'react-i18next';
import { osVersion, mobileModel, osName } from 'react-device-detect';
import * as S from './styles';
import { Customer } from '../../core/ducks/user/types';

interface Props {
  open: boolean;
  onClose: () => void;
  getError: string | undefined;
  getApiErrors: string;
  getCustomer: Customer;
}

const BuyTicketErrorDialog: React.FC<Props> = ({
  open,
  onClose,
  getError,
  getApiErrors,
  getCustomer,
}) => {
  const { t } = useTranslation();

  const statuscode =
    JSON.parse(getApiErrors).BUY_TICKET?.args?.statusCode || '';
  const message = `${t(
    'errors.buyTicket.support.template.body',
  )}\n\nDate: ${new Date().toISOString()}\nCustomer number: ${
    getCustomer.number
  }\nDevice info: ${mobileModel}, ${osName} ${osVersion}\n${
    getError || 'undefined'
  } (${statuscode})`;

  const mailToLink = `mailto:${t(
    'errors.buyTicket.support.email',
  )}?subject=${encodeURI(
    t('errors.buyTicket.support.template.subject'),
  )}&body=${encodeURI(message)}`;

  return (
    <Dialog open={open} onDismiss={onClose}>
      <PopoverContent>
        <Card>
          <Dialog.Content>
            <Dialog.Title>{t('errors.buyTicket.title')}</Dialog.Title>
            <S.DialogMessage>
              {t('errors.buyTicket.description')}
            </S.DialogMessage>
            <S.ButtonGroup alignItems='center' flexDirection='column'>
              <S.Mailto href={mailToLink}>
                <S.Button color='success'>
                  {t('errors.buyTicket.support.title')}
                </S.Button>
              </S.Mailto>
              <S.Button color='secondary' outline onClick={onClose}>
                Close
              </S.Button>
            </S.ButtonGroup>
          </Dialog.Content>
        </Card>
      </PopoverContent>
    </Dialog>
  );
};

export default BuyTicketErrorDialog;
