import { combineReducers } from 'redux';
import { StatusRootState, AuthRootState } from 'pqbc-vas-core';
import { Auth, Status, User, Stations, Tickets } from '../ducks';
import { UserState } from '../ducks/user/reducers';
import { StationsState } from '../ducks/stations/reducers';
import { TicketsState } from '../ducks/tickets/reducers';

export interface State extends AuthRootState, StatusRootState {
  user: UserState;
  stations: StationsState;
  tickets: TicketsState;
}

const rootReducer = combineReducers<State>({
  auth: Auth.reducer,
  status: Status.reducer,
  user: User.reducer,
  stations: Stations.reducer,
  tickets: Tickets.reducer,
});

export default rootReducer;
