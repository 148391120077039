/* eslint-disable react/jsx-props-no-spreading */
import { Field, FieldProps, Form, Formik } from 'formik';
import { Card, CardContent, SegmentedControl } from 'pqbc-vas-design-system';
import { dateToIsoDateString } from 'pqbc-vas-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useHistory } from 'react-router';
import * as S from './styles';
import {
  JourneyType,
  TrainClass,
  Traveler,
} from '../../../core/ducks/user/types';
import bridge from '../../../app/bridge';
import TrackingEvents from '../../../utils/tracking';

interface Props {
  onSubmit: (values: any) => void;
  isSubmitting: boolean;
  currentTraveler: Traveler;
  routeSelected: boolean;
  newTicketData: {
    trainClass: string;
    journeyType: string;
    departureDate: string;
  };
}

const TicketForm: React.FC<Props> = ({
  currentTraveler,
  onSubmit,
  isSubmitting,
  routeSelected,
  newTicketData,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const today = new Date(Date.now());
  const minDate = new Date(today);
  minDate.setHours(0, 0, 0, 0);

  const minDateFormatted = new Date(today).toISOString().substr(0, 10);

  const maxDate = new Date(today.setMonth(minDate.getMonth() + 1))
    .toISOString()
    .substr(0, 10);

  const validTravelerEmail = (traveler: Traveler) =>
    traveler &&
    traveler.email &&
    traveler.email !== '' &&
    traveler.email.toLowerCase() !== 'nmbs.noreply@4411.io';

  const initialValues = {
    departureDate: newTicketData.departureDate
      ? newTicketData.departureDate
      : dateToIsoDateString(minDate),
    traveler: validTravelerEmail(currentTraveler)
      ? `${currentTraveler.firstname} ${currentTraveler.lastname}`
      : '',
    journeyType: newTicketData.journeyType
      ? newTicketData.journeyType
      : JourneyType.Return,
    trainClass: newTicketData.trainClass
      ? newTicketData.trainClass
      : TrainClass.Second,
  };

  type Values = typeof initialValues;

  const handleEditTraveler = () => {
    bridge.trackEvent(TrackingEvents.ChooseTraveler);
    history.push('/traveler');
  };

  const validateDepartureDate = async (value: string) => {
    const schema = Yup.object().shape({
      departureDate: Yup.date().min(minDate, t('form.validation.dateInFuture')),
    });

    try {
      await schema.validate({ departureDate: value });
      return undefined;
    } catch (err) {
      return err.errors[0];
    }
  };

  const validationSchema = Yup.object().shape<Values>({
    departureDate: Yup.string().required(t('form.validation.required.0')),
    traveler: Yup.string().required(),
    journeyType: Yup.mixed<JourneyType>()
      .required()
      .oneOf([JourneyType.Single, JourneyType.Return]),
    trainClass: Yup.mixed<TrainClass>()
      .required()
      .oneOf([TrainClass.First, TrainClass.Second]),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      isInitialValid={() => !!initialValues}
      onSubmit={onSubmit}
      render={({ isValid, errors }) => {
        return (
          <Form>
            <Card>
              <CardContent>
                <Field
                  name='departureDate'
                  validate={validateDepartureDate}
                  render={({ field }: FieldProps<Values>) => (
                    <S.FormItem
                      label={t('form.fields.departureDate')}
                      error={errors.departureDate}
                      fullWidth
                    >
                      <S.DateInput
                        placeholder={t('form.placeholder.departureDate')}
                        disabled={isSubmitting}
                        min={minDateFormatted}
                        max={maxDate}
                        required
                        {...field}
                      />
                    </S.FormItem>
                  )}
                />
                <Field
                  name='traveler'
                  render={({ field }: FieldProps<Values>) => (
                    <S.FormItem label={t('form.fields.traveler')}>
                      <S.TravelerLink onClick={() => history.push('/traveler')}>
                        <S.TravelerInput
                          placeholder={t('form.placeholder.traveler')}
                          suffix={<S.ArrowRight onClick={handleEditTraveler} />}
                          {...field}
                        />
                      </S.TravelerLink>
                    </S.FormItem>
                  )}
                />
                <Field
                  name='journeyType'
                  render={({ field }: FieldProps<Values>) => (
                    <S.FormItem
                      label={t('form.fields.journeyType.title')}
                      fullWidth
                    >
                      <SegmentedControl.Group {...field}>
                        <SegmentedControl.Item value={JourneyType.Single}>
                          {t('form.fields.journeyType.single')}
                        </SegmentedControl.Item>
                        <SegmentedControl.Item value={JourneyType.Return}>
                          {t('form.fields.journeyType.return')}
                        </SegmentedControl.Item>
                      </SegmentedControl.Group>
                    </S.FormItem>
                  )}
                />
                <Field
                  name='trainClass'
                  render={({ field }: FieldProps<Values>) => (
                    <S.FormItem
                      label={t('form.fields.trainClass.title')}
                      fullWidth
                    >
                      <SegmentedControl.Group {...field}>
                        <SegmentedControl.Item value={TrainClass.First}>
                          {t('form.fields.trainClass.first')}
                        </SegmentedControl.Item>
                        <SegmentedControl.Item value={TrainClass.Second}>
                          {t('form.fields.trainClass.second')}
                        </SegmentedControl.Item>
                      </SegmentedControl.Group>
                    </S.FormItem>
                  )}
                />
              </CardContent>
            </Card>
            <S.SubmitButton
              type='submit'
              loading={isSubmitting}
              disabled={
                isSubmitting ||
                !isValid ||
                !routeSelected ||
                !currentTraveler ||
                !validTravelerEmail(currentTraveler)
              }
            >
              {t('getTicket.confirm')}
            </S.SubmitButton>
          </Form>
        );
      }}
    />
  );
};

export default TicketForm;
