import { connect } from 'react-redux';
import TravelerInformationView from './travelerInformationView';
import { State } from '../../core/redux/reducers';
import { User } from '../../core/ducks';
import { Traveler } from '../../core/ducks/user/types';

const mapDispatchToProps = {
  saveTraveler: User.actions.saveTraveler.request,
};

const mapStateToProps = (state: State) => ({
  currentTraveler: User.selectors.getCurrentTraveler(state),
});

export type TravelerInformationProps = {
  saveTraveler: (values: Traveler) => void;
  currentTraveler?: Traveler;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TravelerInformationView);
