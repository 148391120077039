import { ApiResponseBody } from '../../api/types';

export type TermsAndConditionsConsentStatusUpdatedResponse = ApiResponseBody<{
  revision: Date;
  consent_given: boolean;
}>;

export interface TermsAndConditionsConsentStatusResponse
  extends TermsAndConditionsConsentStatusUpdatedResponse {
  text: null;
}

export enum JourneyType {
  Single = 'singletrip',
  Return = 'roundtrip',
}

export enum TrainClass {
  First = 'firstclass',
  Second = 'secondclass',
}

export interface Traveler {
  firstname: string;
  lastname: string;
  gender: string;
  email: string;
  birthdate: Date | string;
}

export interface Customer {
  number: string;
}

export enum Gender {
  Male = 'male',
  Female = 'female',
}
