import {
  Back24Icon,
  NavigationBarButton,
  useAppNavigation,
  NavigationBarExtensionText,
} from 'pqbc-vas-design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { TravelerInformationProps } from './travelerInformationContainer';
import TravelerForm from './travelerForm';
import bridge from '../../app/bridge';
import TrackingEvents from '../../utils/tracking';

const TravelerInformationView = ({
  saveTraveler,
  currentTraveler,
}: TravelerInformationProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  const saveTravelerRequest = (values: any) => {
    bridge.trackEvent(TrackingEvents.SaveTraveler);
    saveTraveler(values);
  };

  useAppNavigation({
    left: (
      <NavigationBarButton onClick={handleBack}>
        <Back24Icon />
      </NavigationBarButton>
    ),
    title: t('travelerInformation.title'),
    extension: (
      <NavigationBarExtensionText>
        {t('travelerInformation.subTitle')}
      </NavigationBarExtensionText>
    ),
  });

  return (
    <>
      <TravelerForm
        onSubmit={values => saveTravelerRequest(values)}
        isSubmitting={false}
        currentTraveler={currentTraveler}
      />
    </>
  );
};

export default TravelerInformationView;
