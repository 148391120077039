import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  BottomSheetDialog,
  ListItem,
  ListItemDivider,
  Flex,
} from 'pqbc-vas-design-system';
import { formatAmount } from 'pqbc-vas-core';
import { useHistory } from 'react-router';
import { Product } from '../../core/ducks/tickets/types';
import bridge from '../../app/bridge';
import TrackingEvents from '../../utils/tracking';
import * as S from './styles';
import { Traveler } from '../../core/ducks/user/types';
import { productIds, productType } from '../../core/ducks/tickets/constants';
import LargeFamilyReminderDialog from '../LargeFamilyReminderDialog';

interface Props {
  open: boolean;
  onClose: () => void;
  availableProducts: Product[];
  traveler: Traveler;
  isLoading: boolean;
  onSelect: (product: Product) => void;
}

const today = new Date();
const adult = new Date(
  today.getFullYear() - 18,
  today.getMonth(),
  today.getDate(),
).getTime();
const child = new Date(
  today.getFullYear() - 12,
  today.getMonth(),
  today.getDate(),
).getTime();

export const isProductAvailableForTraveler = (
  birthdate: string | Date,
  productId: string,
) => {
  const travelerBirthdate = new Date(birthdate).getTime();

  // STANDARD TICKET
  if (productId === productIds.standardTicket) {
    return true;
  }

  // LARGE FAMILY - ADULT
  if (productId === productIds.largeFamilyAdult && travelerBirthdate <= adult) {
    return true;
  }

  // LARGE FAMILY - YOUTH BETWEEN 12-17
  if (
    productId === productIds.largeFamilyYouth &&
    travelerBirthdate > adult &&
    travelerBirthdate < child
  ) {
    return true;
  }
  return false;
};

const AvailableProductsBottomSheetDialog = ({
  open,
  onClose,
  availableProducts,
  traveler,
  isLoading,
  onSelect,
}: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isLargeFamiliesDialogOpen, setIsLargeFamiliesDialogOpen] = useState<
    boolean
  >(false);

  const handleSelectTicket = (product: Product) => {
    if (
      product.id === productIds.largeFamilyAdult ||
      product.id === productIds.largeFamilyYouth
    ) {
      bridge.trackEvent(TrackingEvents.SelectTicket, {
        ticket_type: productType.LargeFamily,
      });
      setIsLargeFamiliesDialogOpen(true);
    } else if (product.id === productIds.standardTicket) {
      bridge.trackEvent(TrackingEvents.SelectTicket, {
        ticket_type: productType.Standard,
      });
      history.push('/tickets/purchase');
    } else {
      history.push('/tickets/purchase');
    }
    onSelect(product);
  };

  return (
    <>
      <BottomSheetDialog
        title={t('availableProducts.title')}
        open={open}
        onDismiss={onClose}
      >
        {isLoading ? (
          <S.LoadingContainer>
            <S.Spinner />
            <S.LoadingDescription>{t('overview.loading')}</S.LoadingDescription>
          </S.LoadingContainer>
        ) : (
          availableProducts
            .filter(product =>
              isProductAvailableForTraveler(traveler.birthdate, product.id),
            )
            .map(product => (
              <React.Fragment key={product.id}>
                <ListItem
                  onClick={() => {
                    handleSelectTicket(product);
                  }}
                >
                  <S.Product>
                    <S.ProductDescription>
                      <S.ProductTitle>
                        {t(`availableProducts.${product.id}.name`, {
                          defaultValue: product.description,
                        })}
                      </S.ProductTitle>
                      <S.ProductSubtitle>
                        {t(`availableProducts.${product.id}.description`, {
                          defaultValue: '',
                        })}
                      </S.ProductSubtitle>
                    </S.ProductDescription>
                    <Flex>
                      {formatAmount(product.price / 100)}{' '}
                      <S.Currency>EUR</S.Currency>
                    </Flex>
                  </S.Product>
                </ListItem>
                <ListItemDivider />
              </React.Fragment>
            ))
        )}
      </BottomSheetDialog>
      <LargeFamilyReminderDialog
        open={isLargeFamiliesDialogOpen}
        onCancel={() => setIsLargeFamiliesDialogOpen(false)}
      />
    </>
  );
};

export default AvailableProductsBottomSheetDialog;
