/* eslint-disable react/jsx-props-no-spreading */
import { Field, FieldProps, Form, Formik } from 'formik';
import {
  Card,
  CardContent,
  SegmentedControl,
  Input,
} from 'pqbc-vas-design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import * as S from './styles';
import { Gender, Traveler } from '../../../core/ducks/user/types';
import { getError } from '../../../utils';

type Values = {
  gender: string | undefined;
  firstname: string | undefined;
  lastname: string | undefined;
  birthdate: string | undefined;
  email: string | undefined;
};

interface Props {
  onSubmit: (values: Values) => void;
  isSubmitting: boolean;
  currentTraveler?: Traveler;
}

const minDate = new Date();
minDate.setHours(0, 0, 0, 0);

const getTravelerInitialValues = (traveler: Traveler | undefined) => {
  if (traveler) {
    return {
      gender: traveler.gender,
      firstname: traveler.firstname,
      lastname: traveler.lastname,
      birthdate: traveler.birthdate.toString(),
      // eslint-disable-next-line no-nested-ternary
      email: traveler.email
        ? traveler.email.toLowerCase() === 'nmbs.noreply@4411.io'
          ? ''
          : traveler.email
        : '',
    };
  }
  return {
    gender: '',
    firstname: '',
    lastname: '',
    birthdate: '',
    email: '',
  };
};

const TravelerForm: React.FC<Props> = ({
  onSubmit,
  isSubmitting,
  currentTraveler,
}) => {
  const { t } = useTranslation();
  const initialValues = getTravelerInitialValues(currentTraveler);

  const validationSchema = Yup.object().shape<Values>({
    gender: Yup.mixed<Gender>()
      .required(t('form.validation.required.gender'))
      .oneOf([Gender.Male, Gender.Female]),
    firstname: Yup.string().required(t('form.validation.required.firstname')),
    lastname: Yup.string().required(t('form.validation.required.lastname')),
    birthdate: Yup.string().required(t('form.validation.required.birthdate')),
    email: Yup.string()
      .email(t('form.validation.email'))
      .required(t('form.validation.required.email')),
  });

  const maxBirthDate = new Date().toISOString().substr(0, 10);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={() => (
        <Form>
          <Card>
            <CardContent>
              <Field
                name='gender'
                render={({ field, form }: FieldProps<Values>) => (
                  <S.FormItem
                    label={t('form.fields.gender.title')}
                    error={getError('gender', form)}
                    fullWidth
                  >
                    <SegmentedControl.Group {...field}>
                      <SegmentedControl.Item value={Gender.Male}>
                        {t('form.fields.gender.male')}
                      </SegmentedControl.Item>
                      <SegmentedControl.Item value={Gender.Female}>
                        {t('form.fields.gender.female')}
                      </SegmentedControl.Item>
                    </SegmentedControl.Group>
                  </S.FormItem>
                )}
              />
              <Field
                name='firstname'
                render={({ field, form }: FieldProps<Values>) => (
                  <S.FormItem
                    label={t('form.fields.firstName')}
                    error={getError('firstname', form)}
                  >
                    <Input
                      placeholder={t('form.placeholder.firstName')}
                      disabled={isSubmitting}
                      {...field}
                    />
                  </S.FormItem>
                )}
              />
              <Field
                name='lastname'
                render={({ field, form }: FieldProps<Values>) => (
                  <S.FormItem
                    label={t('form.fields.lastName')}
                    error={getError('lastname', form)}
                  >
                    <Input
                      placeholder={t('form.placeholder.lastName')}
                      disabled={isSubmitting}
                      {...field}
                    />
                  </S.FormItem>
                )}
              />
              <Field
                name='birthdate'
                render={({ field, form }: FieldProps<Values>) => (
                  <S.FormItem
                    label={t('form.fields.birthDate')}
                    error={getError('birthdate', form)}
                    fullWidth
                  >
                    <S.DateInput
                      placeholder={t('form.placeholder.birthDate')}
                      max={maxBirthDate}
                      disabled={isSubmitting}
                      {...field}
                    />
                  </S.FormItem>
                )}
              />
              <Field
                name='email'
                render={({ field, form }: FieldProps<Values>) => (
                  <S.FormItem
                    label={t('form.fields.email')}
                    error={getError('email', form)}
                  >
                    <Input
                      placeholder={t('form.placeholder.email')}
                      disabled={isSubmitting}
                      {...field}
                    />
                  </S.FormItem>
                )}
              />
            </CardContent>
          </Card>
          <S.SubmitButton
            type='submit'
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            {t('travelerInformation.confirm')}
          </S.SubmitButton>
        </Form>
      )}
    />
  );
};

export default TravelerForm;
