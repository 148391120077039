import styled from 'styled-components';
import { Button, Paragraph, Header3, Note } from 'pqbc-vas-design-system';

export const LoginButton = styled(Button).attrs({
  color: 'success',
})`
  margin-top: 8px;
  width: 100%;
  min-height: 56px;
`;

export const Title = styled(Header3)`
  font-weight: bold;
  text-align: center;
  color: ${props => props.theme.colors.secondary500};
`;

export const IntroParagraph = styled(Paragraph)`
  margin-top: 8px;
  text-align: center;
  color: ${props => props.theme.colors.neutral700};
`;

export const ServiceNote = styled(Note)`
  margin-top: 20px;
`;
