import styled from 'styled-components';
import {
  Button,
  Card as DSCard,
  CardImage,
  Flex,
  Paragraph,
} from 'pqbc-vas-design-system';

export const GetTicketButton = styled(Button)`
  width: 100%;
  margin-top: 10px;
`;

export const NoTicketContainer = styled(DSCard)`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`;

export const NoTicketsImage = styled(CardImage)`
  width: 100%;
  height: 120px;
`;

export const EmptyViewText = styled(Paragraph)`
  color: ${p => p.theme.colors.neutral600};
  text-align: center;
  margin-top: 30px;
`;

export const TabsContainer = styled(Flex)`
  margin-top: 8px;
`;

export const TicketsContainer = styled(Flex)`
  flex-grow: 1;
  flex-direction: column;
  overflow: auto;
`;

export const TicketContainer = styled(DSCard)`
  display: block;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 5px;
  background-color: white;
  padding: 16px;
  flex-direction: column;
  color: ${p => p.theme.colors.secondary500};
`;

export const BottomTicketContainer = styled(Flex)`
  justify-content: space-between;
  height: 100%;
`;

export const DateContainer = styled(Flex)`
  width: 40px;
  flex-direction: column;
  text-align: center;
`;

export const Day = styled(Paragraph)`
  font-size: 22px;
  font-weight: 700;
`;

export const Month = styled(Paragraph)`
  font-size: 15px;
`;

export const RouteIcon = styled.img`
  height: 35px;
  margin: 7px 10px 0 15px;
`;

export const RepurchaseIcon = styled.img`
  height: 16px;
`;

export const RouteContainer = styled(Flex)`
  flex-direction: column;
  flex-grow: 1;

  > span {
    margin: 4px;
  }
`;

export const TravelerContainer = styled(Flex)`
  font-size: 16px;
  text-transform: capitalize;
`;

export const ExpiredText = styled(Flex)`
  font-size: 15px;
  color: ${p => p.theme.colors.neutral600};
`;

export const PriceContainer = styled(Flex)`
  font-size: 16px;
  font-weight: 700;
`;

export const Currency = styled.span`
  font-weight: 400;
  margin: 0 0 0 5px;
  font-size: 16px;
`;
