import { isiOS, isAndroid } from 'pqbc-vas-core';
import {
  Card,
  CardContent,
  CardImage,
  ExternalLink,
  Help24Icon,
  NavigationBarButton,
  useAppNavigation,
  usePopover,
} from 'pqbc-vas-design-system';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router';
import bridge from '../../app/bridge';
import { ReactComponent as Logo4411 } from '../../assets/images/logo-4411.svg';
import onboardingImage from '../../assets/images/onboarding.png';
import { HelpBottomSheetDialog, Spinner } from '../../components';
import TrackingEvents from '../../utils/tracking';
import { OnboardingProps } from './OnboardingContainer';
import * as S from './styles';

let consent = window.localStorage.getItem('approve_t&c_nmbs');

const OnboardingView = ({
  isLoggedIn,
  getAccessToken,
  oAuthCodeChallenge,
  setAccessTokenResponse,
}: OnboardingProps) => {
  const { t, i18n } = useTranslation();
  const { open, handleOpen, handleClose } = usePopover();
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const history = useHistory();

  const handleOpenHelp = () => {
    handleOpen();
    bridge.trackEvent(TrackingEvents.ClickHelp);
  };

  useAppNavigation({
    right: (
      <NavigationBarButton onClick={handleOpenHelp}>
        <Help24Icon />
      </NavigationBarButton>
    ),
  });

  if (isLoggedIn && consent === 'approved') {
    return <Redirect to='/tickets' />;
  }

  if (isLoggingIn) {
    return <Spinner />;
  }

  const handleOpenTermsAndConditions = () => {
    bridge.trackEvent(TrackingEvents.OpenTermsAndConditions);
    const url = t('settings.termsAndConditions.url');
    bridge.openUrl(url);
  };

  const handleOpenPrivacyPolicy = () => {
    bridge.trackEvent(TrackingEvents.OpenPrivacyPolicy);
    const url = t('settings.privacyPolicy.url');
    bridge.openUrl(url);
  };

  const handleGetStarted = () => {
    window.localStorage.setItem('approve_t&c_nmbs', 'approved');
    history.replace('/tickets');
  };

  const handleLogin = async () => {
    bridge.trackEvent(TrackingEvents.Login, {
      start_from: 'nmbs',
    });

    if (consent !== 'approved') {
      window.localStorage.setItem('approve_t&c_nmbs', 'approved');
      consent = 'approved';
    }

    const {
      REACT_APP_ENV,
      REACT_APP_AUTH_URL,
      REACT_APP_CLIENT_ID,
      REACT_APP_API_SCOPE,
      REACT_APP_ACCESS_TOKEN,
    } = process.env;

    if (REACT_APP_ENV !== 'PRODUCTION' && !isiOS() && !isAndroid()) {
      setAccessTokenResponse({ accessToken: REACT_APP_ACCESS_TOKEN as string });
      return;
    }

    const code = await bridge.startAuthorizationCodeGrant({
      url: `${REACT_APP_AUTH_URL}/${i18n.language}/authorize`,
      clientID: REACT_APP_CLIENT_ID,
      scope: REACT_APP_API_SCOPE,
      codeChallenge: oAuthCodeChallenge,
    });

    if (code) {
      getAccessToken({ code });
      setIsLoggingIn(true);
    }
  };

  return (
    <>
      <Card>
        <CardImage src={onboardingImage} objectFit='cover' />
        <CardContent>
          <S.Title>{t('onboarding.title')}</S.Title>
          <S.IntroParagraph>{t('onboarding.description')}</S.IntroParagraph>
          <S.IntroParagraph>
            <Trans
              i18nKey='onboarding.termsAndPolicy'
              components={[
                <ExternalLink onClick={handleOpenTermsAndConditions} />,
                <ExternalLink onClick={handleOpenPrivacyPolicy} />,
              ]}
            />
          </S.IntroParagraph>
          <S.ServiceNote icon={<Logo4411 />}>
            {t('onboarding.serviceBy')}
          </S.ServiceNote>
        </CardContent>
      </Card>
      {isLoggedIn ? (
        <S.LoginButton onClick={handleGetStarted}>
          {t('onboarding.getStarted')}
        </S.LoginButton>
      ) : (
        <S.LoginButton onClick={handleLogin}>
          {t('onboarding.login')}
        </S.LoginButton>
      )}
      <HelpBottomSheetDialog open={open} onClose={handleClose} />
    </>
  );
};

export default OnboardingView;
