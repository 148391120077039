import { useState, useEffect, useMemo } from 'react';
import FuzzySearch from 'fuzzy-search';
import { Station } from '../../core/ducks/stations/types';

export const useStationSearch = (needle: string, stations: Station[]) => {
  const [matchingStations, setMatchingStations] = useState<Station[]>([]);

  const searcher = useMemo(
    () => new FuzzySearch(stations, ['name'], { sort: true }),
    [stations],
  );

  useEffect(() => {
    setMatchingStations(searcher.search(needle).slice(0, 6));
  }, [needle, searcher]);

  return matchingStations;
};
