import { connect } from 'react-redux';
import TicketPurchaseOverviewView from './ticketPurchaseOverviewView';
import { User, Tickets } from '../../core/ducks';
import { State } from '../../core/redux/reducers';

const mapStateToProps = (state: State) => ({
  currentTraveler: User.selectors.getCurrentTraveler(state),
  newTicketData: Tickets.selectors.getNewTicketData(state),
  customerNumber: User.selectors.getCustomer(state).number,
  isLoading: Tickets.selectors.getBuyTicketLoading(state),
});

const mapDispatchToProps = {
  startPayment: Tickets.actions.startPayconiqPayment.request,
};

export type TicketPurchaseOverviewProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TicketPurchaseOverviewView);
