import { takeEvery, put, call, select } from 'redux-saga/effects';
import { ducks, HTTPMethod } from 'pqbc-vas-core';
import history from '../../../../app/history';
import * as actions from '../actions';
import * as selectors from '../selectors';
import {
  TermsAndConditionsConsentStatusResponse,
  TermsAndConditionsConsentStatusUpdatedResponse,
} from '../types';

export function* fetchTermsAndConditionsConsentStatusSaga() {
  try {
    const response: TermsAndConditionsConsentStatusResponse = yield call(
      ducks.Api.sagas.callApi,
      '/terms-and-conditions',
      {
        method: HTTPMethod.GET,
        headers: { '4411-client-secret': process.env.REACT_APP_CLIENT_SECRET },
      },
    );
    yield put(actions.fetchTermsAndConditionsConsentStatus.success(response));
  } catch (error) {
    yield put(actions.fetchTermsAndConditionsConsentStatus.failure(error));
  }
}

export function* acceptLatestTermsAndConditionsSaga() {
  try {
    const response: TermsAndConditionsConsentStatusUpdatedResponse = yield call(
      ducks.Api.sagas.callApi,
      '/terms-and-conditions',
      {
        method: HTTPMethod.PUT,
        headers: { '4411-client-secret': process.env.REACT_APP_CLIENT_SECRET },
      },
    );
    yield put(actions.acceptLatestTermsAndConditions.success(response));
  } catch (error) {
    yield put(actions.acceptLatestTermsAndConditions.failure(error));
  }
}

export function* getCustomerProfileSaga() {
  try {
    const response = yield call(
      ducks.Api.sagas.callApi,
      '/customer/customers',
      {
        method: HTTPMethod.GET,
        headers: { '4411-client-secret': process.env.REACT_APP_CLIENT_SECRET },
      },
    );
    yield put(actions.getCustomerProfile.success(response.content[0]));
  } catch (error) {
    yield put(actions.getCustomerProfile.failure(error));
  }
}

export function* getTravelersSaga() {
  try {
    const response = yield call(ducks.Api.sagas.callApi, '/nmbs/travelers', {
      method: HTTPMethod.GET,
      headers: { '4411-client-secret': process.env.REACT_APP_CLIENT_SECRET },
    });
    yield put(actions.getTravelers.success(response.content));
  } catch (error) {
    yield put(actions.saveTraveler.failure(error));
  }
}

export function* saveTravelerSaga(
  action: ReturnType<typeof actions.saveTraveler.request>,
) {
  try {
    const currentTraveler = yield select(selectors.getCurrentTraveler);
    if (currentTraveler) {
      yield call(
        ducks.Api.sagas.callApi,
        `/nmbs/travelers/${currentTraveler.traveler_id}`,
        {
          method: HTTPMethod.PUT,
          headers: {
            '4411-client-secret': process.env.REACT_APP_CLIENT_SECRET,
          },
          body: {
            gender: action.payload.gender,
            firstname: action.payload.firstname,
            lastname: action.payload.lastname,
            birthdate: action.payload.birthdate,
            email: action.payload.email,
          },
        },
      );
      yield put(actions.saveTraveler.success(action.payload));
    } else {
      const response = yield call(ducks.Api.sagas.callApi, '/nmbs/travelers', {
        method: HTTPMethod.POST,
        headers: { '4411-client-secret': process.env.REACT_APP_CLIENT_SECRET },
        body: {
          gender: action.payload.gender,
          firstname: action.payload.firstname,
          lastname: action.payload.lastname,
          birthdate: action.payload.birthdate,
          email: action.payload.email,
        },
      });
      yield put(actions.saveTraveler.success(response.content));
    }
    history.push('tickets/buy');
  } catch (error) {
    yield put(actions.saveTraveler.failure(error));
  }
}

export function* UserSaga() {
  yield takeEvery(
    actions.fetchTermsAndConditionsConsentStatus.request,
    fetchTermsAndConditionsConsentStatusSaga,
  );
  yield takeEvery(
    actions.acceptLatestTermsAndConditions.request,
    acceptLatestTermsAndConditionsSaga,
  );
  yield takeEvery(actions.getTravelers.request, getTravelersSaga);
  yield takeEvery(actions.saveTraveler.request, saveTravelerSaga);
  yield takeEvery(actions.getCustomerProfile.request, getCustomerProfileSaga);
}
