import { createAsyncAction, createAction } from 'typesafe-actions';
import { Ticket, Product } from '../types';

export const getMyTickets = createAsyncAction(
  'GET_MY_TICKETS_REQUEST',
  'GET_MY_TICKETS_SUCCESS',
  'GET_MY_TICKETS_FAILURE',
)<void, { available: Ticket[]; expired: Ticket[] }, Error>();

export const getAvailableProducts = createAsyncAction(
  'GET_AVAILABLE_PRODUCTS_REQUEST',
  'GET_AVAILABLE_PRODUCTS_SUCCESS',
  'GET_AVAILABLE_PRODUCTS_FAILURE',
)<
  {
    departureDate: Date;
    departureStationId: string;
    destinationStationId: string;
    journeyType: string;
    travelClass: string;
  },
  Product[],
  Error
>();

export const saveNewTicket = createAction('SAVE_NEW_TICKET')<{
  productId?: string;
  departureStation?: {
    id: string;
    name: string;
  };
  destinationStation?: {
    id: string;
    name: string;
  };
  trainClass?: string;
  journeyType?: string;
  departureDate?: string | Date;
  price?: number;
  totalPrice?: number;
  transactionCost?: number;
}>();
export const clearNewTicketData = createAction('CLEAR_NEW_TICKET_DATA')();

export const startPayconiqPayment = createAsyncAction(
  'START_PAYCONIQ_PAYMENT_REQUEST',
  'START_PAYCONIQ_PAYMENT_SUCCESS',
  'START_PAYCONIQ_PAYMENT_FAILURE',
)<
  {
    customerNumber: string;
    cost: number;
  },
  void,
  Error
>();

export const buyTicket = createAsyncAction(
  'BUY_TICKET_REQUEST',
  'BUY_TICKET_SUCCESS',
  'BUY_TICKET_FAILURE',
)<void, void, Error>();
