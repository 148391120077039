import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import {
  BottomSheetDialog,
  ListItem,
  ListItemDivider,
  ListItemIcon,
  ListItemLabel,
} from 'pqbc-vas-design-system';
import { formatDate } from 'pqbc-vas-core';
import repeatIcon from '../../assets/images/repeat.svg';
import ticketIcon from '../../assets/images/ticket-icon.svg';
import bridge from '../../app/bridge';
import TrackingEvents from '../../utils/tracking';
import { Ticket } from '../../core/ducks/tickets/types';
import { Tickets } from '../../core/ducks';

const mapDispatchToProps = {
  saveNewTicketData: Tickets.actions.saveNewTicket,
};

type DispatchActions = typeof mapDispatchToProps;

interface Props extends DispatchActions {
  open: boolean;
  onClose: () => void;
  ticket: Ticket;
}

const Icon = styled.img`
  width: 24px;
`;

const openTicketDetail = (ticketId: string) => {
  bridge.trackEvent(TrackingEvents.ShowTicketDetail, {
    start_from: 'ns_history',
  });
  bridge.openTicket(ticketId.toString());
};

const ExpiredTicketBottomSheetDialog = ({
  open,
  onClose,
  ticket,
  saveNewTicketData,
}: Props) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <BottomSheetDialog
      title={`${t('overview.ticket')}: ${formatDate(
        new Date(ticket.time_start),
      )}`}
      open={open}
      onDismiss={onClose}
    >
      <ListItem onClick={() => openTicketDetail(ticket.id)}>
        <ListItemIcon>
          <Icon src={ticketIcon} />
        </ListItemIcon>
        <ListItemLabel>{t('expiredTicket.viewTicket')}</ListItemLabel>
      </ListItem>
      <ListItemDivider />
      <ListItem
        onClick={() => {
          saveNewTicketData({
            journeyType: ticket.details.journey_type,
            trainClass: ticket.details.traveler_class,
            departureStation: {
              id: ticket.details.departure_station_id,
              name: ticket.details.departure_station,
            },
            destinationStation: {
              id: ticket.details.destination_station_id,
              name: ticket.details.destination_station,
            },
          });
          bridge.trackEvent(TrackingEvents.ClickBuyTicket, {
            started_from: 'buy_again',
          });
          history.push('/tickets/buy');
        }}
      >
        <ListItemIcon>
          <Icon src={repeatIcon} />
        </ListItemIcon>
        <ListItemLabel>{t('expiredTicket.buyAgain')}</ListItemLabel>
      </ListItem>
    </BottomSheetDialog>
  );
};

export default connect(
  undefined,
  mapDispatchToProps,
)(ExpiredTicketBottomSheetDialog);
