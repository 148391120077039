import styled from 'styled-components';
import {
  Divider as DSDivider,
  CardContent as DSCardContent,
  NavigationBarButton,
  Card,
} from 'pqbc-vas-design-system';

export const Wrapper = styled.div`
  max-width: 450px;
  margin: 0 auto;
  position: relative;
`;

export const CardContent = styled(DSCardContent)`
  padding: 20px 16px;
`;

export const RouteIcon = styled.img`
  height: 60px;
  width: 10px;
  margin-right: 20px;
`;

export const Fields = styled.div`
  flex-grow: 1;
`;

export const Divider = styled(DSDivider)`
  margin: 4px 0;
`;

interface InputProps {
  isBottom?: boolean;
}

export const Input = styled.input<InputProps>`
  border: none;
  outline: none;
  color: ${p => p.theme.colors.secondary500};
  font-size: 18px;
  padding: 10px 0;
  margin-bottom: ${p => (p.isBottom ? '-10px' : 0)};
  margin-top: ${p => (p.isBottom ? 0 : '-10px')};
  width: 100%;

  &::placeholder {
    color: ${p => p.theme.colors.neutral400};
    line-height: initial;
  }
`;

export const ReverseButton = styled(NavigationBarButton)`
  margin-left: 20px;
  padding: 0;
`;

export const ReverseIcon = styled.img`
  width: 32px;
  height: 32px;
`;

export const StationsCard = styled(Card)`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 2;
  margin-top: 10px;
`;

export const HighLightedText = styled.span`
  color: ${p => p.theme.colors.primary500};
`;

export const StationsCardContent = styled(DSCardContent)`
  padding: 10px 0;
`;

export const Clickable = styled.button<{ isFocused: boolean }>`
  background: none;
  outline: none;
  border: none;
  margin: 0;
  text-align: left;
  cursor: pointer;
  display: block;
  padding: 10px 16px;
  width: 100%;
  box-sizing: border-box;
  color: ${p => p.theme.colors.secondary500};
  background: ${p => (p.isFocused ? p.theme.colors.neutral200 : 'none')};

  &:hover,
  &:active {
    background: ${p => p.theme.colors.neutral200};
  }
`;
