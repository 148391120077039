import { Divider, Flex } from 'pqbc-vas-design-system';
import i18n from 'i18next';
import React from 'react';
import { formatAmount } from 'pqbc-vas-core';
import routeIcon from '../../assets/images/route-icon.svg';
import bridge from '../../app/bridge';
import TrackingEvents from '../../utils/tracking';
import { Ticket } from '../../core/ducks/tickets/types';
import * as S from './styles';

const openTicketDetail = (ticketId: string) => {
  bridge.trackEvent(TrackingEvents.ShowTicketDetail, {
    start_from: 'ns_overview',
  });
  bridge.openTicket(ticketId.toString());
};

const ActiveTicket = ({ ticket }: { ticket: Ticket }) => {
  return (
    <S.TicketContainer onClick={() => openTicketDetail(ticket.id)}>
      <Flex>
        <S.DateContainer>
          <S.Day>{new Date(ticket.details.departure_date).getDate()}</S.Day>
          <S.Month>
            {new Date(ticket.details.departure_date).toLocaleDateString(
              i18n.language,
              {
                month: 'short',
              },
            )}
          </S.Month>
        </S.DateContainer>
        <S.RouteIcon src={routeIcon} />
        <S.RouteContainer>
          <span>{ticket.details.departure_station}</span>
          <span>{ticket.details.destination_station}</span>
        </S.RouteContainer>
      </Flex>
      <Divider />
      <S.BottomTicketContainer>
        <S.TravelerContainer>
          {ticket.details.travelers[0].firstname}{' '}
          {ticket.details.travelers[0].lastname}
        </S.TravelerContainer>
        <S.PriceContainer>
          {formatAmount(ticket.cost / 100)} <S.Currency>EUR</S.Currency>
        </S.PriceContainer>
      </S.BottomTicketContainer>
    </S.TicketContainer>
  );
};

export default ActiveTicket;
