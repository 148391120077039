import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import * as S from './styles';
import { UpdatedTermsAndConditionsDialog } from '../../../components';
import {
  Onboarding,
  TicketOverview,
  BuyTicket,
  TravelerInformation,
} from '../../../screens';
import PrivateRoute from '../PrivateRoute';
import TicketPurchaseOverview from '../../../screens/ticketPurchaseOverview/ticketPurchaseOverviewContainer';

const Routes = () => (
  <S.Main>
    <UpdatedTermsAndConditionsDialog />
    <Switch>
      <Route exact path='/' component={Onboarding} />
      <PrivateRoute exact path='/tickets' component={TicketOverview} />
      <PrivateRoute exact path='/tickets/buy' component={BuyTicket} />
      <PrivateRoute exact path='/traveler' component={TravelerInformation} />
      <PrivateRoute
        exact
        path='/tickets/purchase'
        component={TicketPurchaseOverview}
      />
      <Redirect from='*' to='/' />
    </Switch>
  </S.Main>
);

export default Routes;
