import {
  Close24Icon,
  NavigationBarButton,
  useAppNavigation,
  usePopover,
} from 'pqbc-vas-design-system';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import {
  RouteSelector,
  CancelGetTicketDialog,
  AvailableProductsBottomSheetDialog,
} from '../../components';
import { BuyTicketProps } from './BuyTicketContainer';
import TicketForm from './TicketForm';
import bridge from '../../app/bridge';
import TrackingEvents from '../../utils/tracking';

const BuyTicketView = ({
  currentTraveler,
  getTravelers,
  stations,
  getAvailableProducts,
  availableProducts,
  isLoadingProducts,
  saveNewTicketData,
  newTicketData,
  clearNewTicketData,
}: BuyTicketProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState<boolean>(false);
  const { open, handleOpen, handleClose } = usePopover();

  useEffect(() => {
    getTravelers();
  }, [getTravelers]);

  useAppNavigation(
    {
      left: null,
      right: (
        <NavigationBarButton onClick={() => setIsCancelDialogOpen(true)}>
          <Close24Icon />
        </NavigationBarButton>
      ),
      title: t('getTicket.title'),
      extension: (
        <RouteSelector
          stations={stations}
          selectedStations={{
            departure: newTicketData.departureStation,
            destination: newTicketData.destinationStation,
          }}
        />
      ),
    },
    [stations],
  );

  return (
    <>
      <TicketForm
        onSubmit={values => {
          // Keep values in state to show on the overview screen before buying the ticket.
          saveNewTicketData({
            departureDate: values.departureDate,
            journeyType: values.journeyType,
            trainClass: values.trainClass,
          });

          if (
            newTicketData.departureStation.id &&
            newTicketData.destinationStation.id
          ) {
            getAvailableProducts({
              departureDate: values.departureDate,
              departureStationId: newTicketData.departureStation.id,
              destinationStationId: newTicketData.destinationStation.id,
              journeyType: values.journeyType,
              travelClass: values.trainClass,
            });
          }
          bridge.trackEvent(TrackingEvents.ShowTickets);
          handleOpen();
        }}
        routeSelected={
          !!(
            newTicketData.departureStation.name &&
            newTicketData.destinationStation.name
          )
        }
        isSubmitting={false}
        currentTraveler={currentTraveler}
        newTicketData={newTicketData}
      />
      <CancelGetTicketDialog
        open={isCancelDialogOpen}
        onCancel={() => setIsCancelDialogOpen(false)}
        onConfirm={() => {
          clearNewTicketData();
          history.push('/tickets');
        }}
      />
      <AvailableProductsBottomSheetDialog
        open={open}
        onClose={handleClose}
        availableProducts={availableProducts}
        traveler={currentTraveler}
        isLoading={isLoadingProducts}
        onSelect={product => {
          saveNewTicketData({
            productId: product.id,
            price: product.price,
            totalPrice: product.total_price,
            transactionCost: product.transaction_cost,
          });
        }}
      />
    </>
  );
};

export default BuyTicketView;
