import { Reducer } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from '../actions';
import { Station } from '../types';

export type StationsState = Station[];

export const initialState: StationsState = [];

const StationsReducer: Reducer<StationsState, ActionType<typeof actions>> = (
  state = initialState,
  action,
): StationsState => {
  switch (action.type) {
    case getType(actions.getStations.success): {
      return action.payload;
    }
    default:
      return state;
  }
};

export default StationsReducer;
