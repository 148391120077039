import { connect } from 'react-redux';
import BuyTicketView from './BuyTicketView';
import { User, Stations, Status, Tickets } from '../../core/ducks';
import { State } from '../../core/redux/reducers';

const mapStateToProps = (state: State) => ({
  stations: Stations.selectors.getStations(state),
  currentTraveler: User.selectors.getCurrentTraveler(state),
  availableProducts: Tickets.selectors.getAvailableProducts(state),
  isLoadingProducts: Status.selectors.isLoading(
    Tickets.constants.GET_AVAILABLE_PRODUCTS,
  )(state),
  newTicketData: Tickets.selectors.getNewTicketData(state),
});

const mapDispatchToProps = {
  getTravelers: User.actions.getTravelers.request,
  getAvailableProducts: Tickets.actions.getAvailableProducts.request,
  saveNewTicketData: Tickets.actions.saveNewTicket,
  clearNewTicketData: Tickets.actions.clearNewTicketData,
};

export type BuyTicketProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(BuyTicketView);
