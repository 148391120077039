import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  ListItem,
  ListItemIcon,
  ListItemLabel,
  ListItemDivider,
  Logout24Icon,
  Show24Icon,
  Terms24Icon,
} from 'pqbc-vas-design-system';

import HelpBottomSheetDialog from '../HelpBottomSheetDialog';
import { Auth } from '../../core/ducks';

import bridge from '../../app/bridge';
import TrackingEvents from '../../utils/tracking';
import LogoutWarningDialog from '../LogoutWarningDialog';

const mapDispatchToProps = {
  logout: Auth.actions.logout.request,
};

type DispatchActions = typeof mapDispatchToProps;

interface Props extends DispatchActions {
  open: boolean;
  onClose: () => void;
}

const SettingsBottomSheetDialog = ({ open, onClose, logout }: Props) => {
  const { t } = useTranslation();
  const [isLogoutWarningVisible, setIsLogoutWarningVisible] = useState(false);

  const handleOpenTermsAndConditions = () => {
    bridge.trackEvent(TrackingEvents.OpenTermsAndConditions);
    const url = t('settings.termsAndConditions.url');
    bridge.openUrl(url);
  };

  const handleOpenPrivacyPolicy = () => {
    bridge.trackEvent(TrackingEvents.OpenPrivacyPolicy);
    const url = t('settings.privacyPolicy.url');
    bridge.openUrl(url);
  };

  const showLogoutWarning = () => {
    onClose();
    bridge.trackEvent(TrackingEvents.LogOut);
    setIsLogoutWarningVisible(true);
  };

  const handleLogout = () => {
    setIsLogoutWarningVisible(false);
    bridge.trackEvent(TrackingEvents.CompleteLogout);
    bridge.deleteAllTickets();
    logout();
  };

  return (
    <>
      <HelpBottomSheetDialog
        title={t('settings.title')}
        open={open}
        onClose={onClose}
      >
        <ListItemDivider />
        <ListItem onClick={handleOpenTermsAndConditions}>
          <ListItemIcon>
            <Terms24Icon />
          </ListItemIcon>
          <ListItemLabel>
            {t('settings.termsAndConditions.title')}
          </ListItemLabel>
        </ListItem>
        <ListItemDivider />
        <ListItem onClick={handleOpenPrivacyPolicy}>
          <ListItemIcon>
            <Show24Icon />
          </ListItemIcon>
          <ListItemLabel>{t('settings.privacyPolicy.title')}</ListItemLabel>
        </ListItem>
        <ListItemDivider />
        <ListItem onClick={showLogoutWarning}>
          <ListItemIcon>
            <Logout24Icon />
          </ListItemIcon>
          <ListItemLabel>{t('settings.logout.title')}</ListItemLabel>
        </ListItem>
      </HelpBottomSheetDialog>
      <LogoutWarningDialog
        open={isLogoutWarningVisible}
        onLogout={handleLogout}
        onDismiss={() => setIsLogoutWarningVisible(false)}
      />
    </>
  );
};

export default connect(
  undefined,
  mapDispatchToProps,
)(SettingsBottomSheetDialog);
