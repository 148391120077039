import { Divider, Flex, usePopover } from 'pqbc-vas-design-system';
import { connect } from 'react-redux';
import { formatAmount } from 'pqbc-vas-core';
import i18n from 'i18next';
import React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import bridge from '../../app/bridge';
import TrackingEvents from '../../utils/tracking';
import routeIcon from '../../assets/images/route-icon.svg';
import repurchaseIcon from '../../assets/images/repeat.svg';
import { ExpiredTicketBottomSheetDialog } from '../../components';
import { Ticket } from '../../core/ducks/tickets/types';
import { Tickets } from '../../core/ducks';

import * as S from './styles';

const mapDispatchToProps = {
  saveNewTicketData: Tickets.actions.saveNewTicket,
};

type DispatchActions = typeof mapDispatchToProps;

interface Props extends DispatchActions {
  ticket: Ticket;
}

const ExpiredTicket = ({ ticket, saveNewTicketData }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { open, handleOpen, handleClose } = usePopover();

  return (
    <S.TicketContainer onClick={() => handleOpen()}>
      <Flex>
        <S.DateContainer>
          <S.Day>{new Date(ticket.details.departure_date).getDate()}</S.Day>
          <S.Month>
            {new Date(ticket.details.departure_date).toLocaleDateString(
              i18n.language,
              {
                month: 'short',
              },
            )}
          </S.Month>
        </S.DateContainer>
        <S.RouteIcon src={routeIcon} />
        <S.RouteContainer>
          <span>{ticket.details.departure_station}</span>
          <span>{ticket.details.destination_station}</span>
        </S.RouteContainer>

        <S.RepurchaseIcon
          src={repurchaseIcon}
          onClick={event => {
            event.stopPropagation();
            saveNewTicketData({
              journeyType: ticket.details.journey_type,
              trainClass: ticket.details.traveler_class,
              departureStation: {
                id: ticket.details.departure_station_id,
                name: ticket.details.departure_station,
              },
              destinationStation: {
                id: ticket.details.destination_station_id,
                name: ticket.details.destination_station,
              },
            });
            bridge.trackEvent(TrackingEvents.ClickBuyTicket, {
              started_from: 'buy_again',
            });
            history.push('/tickets/buy');
          }}
        />
      </Flex>
      <Divider />
      <S.BottomTicketContainer>
        <S.ExpiredText>{t('overview.expired')}</S.ExpiredText>
        <S.PriceContainer>
          {formatAmount(ticket.cost / 100)} <S.Currency>EUR</S.Currency>
        </S.PriceContainer>
      </S.BottomTicketContainer>
      <ExpiredTicketBottomSheetDialog
        open={open}
        onClose={handleClose}
        ticket={ticket}
      />
    </S.TicketContainer>
  );
};

export default connect(undefined, mapDispatchToProps)(ExpiredTicket);
