import { Reducer } from 'redux';
import { merge } from 'lodash';
import { ActionType, getType } from 'typesafe-actions';
import { dateToIsoDateString } from 'pqbc-vas-core';
import * as actions from '../actions';
import { Ticket, Product } from '../types';

export type TicketsState = {
  available: Ticket[];
  expired: Ticket[];
  availableProducts: Product[];
  newTicket: {
    productId: string;
    departureStation: {
      id?: string;
      name: string;
    };
    destinationStation: {
      id?: string;
      name: string;
    };
    trainClass: string;
    journeyType: string;
    departureDate: string;
    price: number | null;
    totalPrice: number | null;
    transactionCost: number | null;
  };
  buyTicketLoading: boolean;
};

export const initialState: TicketsState = {
  available: [],
  expired: [],
  availableProducts: [],
  newTicket: {
    productId: '',
    departureStation: {
      id: '',
      name: '',
    },
    destinationStation: {
      id: '',
      name: '',
    },
    trainClass: '',
    journeyType: '',
    departureDate: dateToIsoDateString(new Date()),
    price: null,
    totalPrice: null,
    transactionCost: null,
  },
  buyTicketLoading: false,
};

const TicketsReducer: Reducer<TicketsState, ActionType<typeof actions>> = (
  state = initialState,
  action,
): TicketsState => {
  switch (action.type) {
    case getType(actions.getMyTickets.success): {
      return {
        ...state,
        available: action.payload.available,
        expired: action.payload.expired,
      };
    }
    case getType(actions.getAvailableProducts.success): {
      return {
        ...state,
        availableProducts: action.payload,
      };
    }
    case getType(actions.saveNewTicket): {
      return {
        ...state,
        newTicket: { ...merge(state.newTicket, action.payload) },
      };
    }
    case getType(actions.clearNewTicketData): {
      return {
        ...state,
        newTicket: {
          productId: '',
          departureStation: {
            id: '',
            name: '',
          },
          destinationStation: {
            id: '',
            name: '',
          },
          trainClass: '',
          journeyType: '',
          departureDate: dateToIsoDateString(new Date()),
          price: null,
          totalPrice: null,
          transactionCost: null,
        },
      };
    }
    case getType(actions.buyTicket.request): {
      return {
        ...state,
        buyTicketLoading: true,
      };
    }
    case getType(actions.buyTicket.success):
    case getType(actions.buyTicket.failure): {
      return {
        ...state,
        buyTicketLoading: false,
      };
    }
    default:
      return state;
  }
};

export default TicketsReducer;
