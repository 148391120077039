import styled, { css } from 'styled-components';
import {
  Button,
  FormItem as DSFormItem,
  Input,
  ArrowRight16Icon,
} from 'pqbc-vas-design-system';

export const SubmitButton = styled(Button).attrs({
  color: 'success',
})`
  margin-top: 8px;
  width: 100%;
`;

export const FormItem = styled(DSFormItem)`
  margin-bottom: 16px;
`;

export const DateInput = styled(Input).attrs({
  type: 'date',
})`
  ::-webkit-clear-button {
    display: none;
  }
  /* show the placeholder for input type date */
  &:before {
    color: ${props => props.theme.colors.neutral400};
    content: attr(placeholder);
    width: 100%;
  }
  /* hide placeholder when focusing */
  &:focus:before {
    content: '';
    width: auto;
  }
  /* hide placeholder when a value has been chosen */
  ${p =>
    p.value &&
    css`
      &:before {
        content: '';
        width: auto;
      }
    `}
`;

export const TravelerLink = styled.a`
  text-decoration: none;
  display: block;

  & > * {
    width: 100%;
  }
`;

export const TravelerInput = styled(Input)`
  width: 100%;

  &:disabled {
    background: none;
    /* IOS */
    opacity: 1;
  }
`;

export const ArrowRight = styled(ArrowRight16Icon)`
  stroke: ${p => p.theme.colors.secondary500};
  height: 16px;
  margin-bottom: 3px;
  margin-left: 4px;
`;
